var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ranchoredpopup",
    class: [_vm.anchor, {
      active: _vm.showPopup
    }]
  }, [_c('div', {
    staticClass: "relative-wrapper"
  }, [_c('r-button', {
    ref: "anchorButton",
    staticClass: "anchor-button ml-2",
    attrs: {
      "disabled": _vm.disabled,
      "icon": _vm.icon,
      "fab": _vm.fabIcon,
      "outlined": "",
      "small": _vm.smallButton
    },
    on: {
      "click": _vm.onButtonClick
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('span', {
          class: {
            'pl-1': !!_vm.label
          }
        }, [_vm._v(_vm._s(_vm.label))])];
      },
      proxy: true
    }])
  }), _vm.showPopup ? _c('div', {
    ref: "anchorCardContainer",
    staticClass: "anchor-card--container",
    attrs: {
      "min-width": _vm.width,
      "min-height": _vm.height
    }
  }, [_c('v-card', [_vm.anchor === 'top-left' || _vm.anchor === 'top-right' ? _c('div', {
    staticClass: "popup-button-wrapper"
  }, [_c('r-button', {
    attrs: {
      "label": _vm.label,
      "icon": _vm.icon,
      "fab": _vm.fabIcon,
      "small": _vm.smallButton
    },
    on: {
      "click": _vm.onClosePopup
    }
  })], 1) : _vm._e(), _c('div', {
    ref: "contentwrapper",
    staticClass: "content-wrapper"
  }, [_vm._t("default", null, {
    "onClose": _vm.onClosePopup
  })], 2), _vm.anchor === 'bottom-left' || _vm.anchor === 'bottom-right' ? _c('div', {
    staticClass: "popup-button-wrapper"
  }, [_c('r-button', {
    attrs: {
      "label": _vm.label,
      "icon": _vm.icon,
      "fab": _vm.fabIcon,
      "small": _vm.smallButton
    },
    on: {
      "click": _vm.onClosePopup
    }
  })], 1) : _vm._e()])], 1) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }