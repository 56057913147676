var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rdatafilterchips"
  }, _vm._l(_vm.items, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "rdatafilterchips--chip"
    }, [_c('span', {
      staticClass: "rdatafilterchips--label"
    }, [_vm._v(" " + _vm._s("".concat(item.label, ": ").concat(item.value)) + " ")]), _c('v-icon', {
      staticClass: "rdatafilterchips--icon",
      on: {
        "click": function click($event) {
          return _vm.onRemove(item);
        }
      }
    }, [_vm._v(" close ")])], 1);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }