<template>
  <v-row class="ma-0 hostnamesdropdown d-flex justify-center">
    <r-select
      class="hostnamesdropdown--select"
      multiple
      :items="filteredHostnames"
      :selected-items-for-chip="selectedHostnameItems"
      @change:selected="onSelectHostname"
      @change:deselected="onDeselectHostname"
      @change:search="onSearchChange"
    >
      <template
        v-if="selectLabel"
        v-slot:label
      >
        <span>{{ selectLabel }}</span>
      </template>
    </r-select>
  </v-row>
</template>

<script>
import RSelect from '@/components/library/molecules/RSelect'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'HostnamesDropdown',
  components: {
    RSelect,
  },
  props: {
    preselectedHostnames: {
      type: Array,
      default: () => [],
    },
    selectLabel: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    search: '',
    selectedHostnameItems: [],
  }),
  computed: {
    ...mapState('urls', [
      'hostnames',
    ]),
    items() {
      return this.mapHostnamesToDropdownItems({
        hostnames: this.hostnames,
      })
    },
    filteredHostnames() {
      const { search } = this.$data
      if (!search) {
        return this.items
      }
      const lowercasedSearch = search.toLowerCase()
      return this.items.filter(({ label }) => label.toLowerCase().includes(lowercasedSearch))
    },
    selectedHostnames() {
      return this.$data.selectedHostnameItems.map(({ value }) => value)
    },
  },
  async mounted() {
    const { preselectedHostnames } = this.$props
    if (!this.hostnames.length) {
      await this.fetchHostnames()
    }
    this.$data.selectedHostnameItems = preselectedHostnames.map(({ name }) => ({
      label: name,
      value: name,
    }))
    this.$watch('selectedHostnames', this.watchselectedHostnamesChange)
  },
  methods: {
    ...mapActions('urls', [
      'fetchHostnames',
    ]),
    onSearchChange({ value }) {
      this.$data.search = value
    },
    onSelectHostname({ value: hostname }) {
      this.$data.selectedHostnameItems = [...this.$data.selectedHostnameItems, hostname]
    },
    onDeselectHostname({ value: hostname }) {
      this.$data.selectedHostnameItems = this.$data.selectedHostnameItems
        .filter(({ value }) => value !== hostname.value)
    },
    mapHostnamesToDropdownItems({ hostnames }) {
      return hostnames.map(({ name }) => ({
        label: name,
        value: name,
        selected: this.selectedHostnames.includes(name),
      }))
    },
    watchselectedHostnamesChange() {
      this.$emit('change', { value: this.selectedHostnames, items: this.items })
    },
  },
}
</script>

<style scoped>
.hostnamesdropdown--select {
  display: flex;
  flex-grow: 1;
  align-items: center;
  align-content: center;
  gap: 10px;
}
</style>
