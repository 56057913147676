var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-rating', {
    staticClass: "rrating pa-0 ma-0",
    attrs: {
      "value": _vm.value,
      "readonly": _vm.readonly,
      "half-increments": _vm.isHalfIncrementing,
      "size": 16,
      "half-icon": "mdi-star-half-full",
      "color": "yellow darken-2",
      "background-color": "yellow darken-2",
      "dense": ""
    },
    on: {
      "input": _vm.onChange
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }