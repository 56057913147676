var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "creatempd"
  }, [_vm.step === 1 ? _c('r-box', {
    staticClass: "creatempd--formbox mb-9"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('r-text-field', {
    attrs: {
      "value": _vm.name,
      "label": _vm.$t('dashboards.createDashboard.name'),
      "placeholder": "Samsung S10"
    },
    on: {
      "change": _vm.onChangeDashboardName
    }
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "d-inline-flex align-center justify-end ",
    attrs: {
      "cols": "5"
    }
  }, [_c('div', [_c('label', {
    staticClass: "creatempd--template d-block pb-4"
  }, [_vm._v("Selected Template")]), _c('r-dashboard-type-card', {
    attrs: {
      "type": _vm.TYPES.MULTI_PRODUCT,
      "label": "Multi Product Comparison"
    }
  })], 1)])], 1)], 1) : _vm._e(), _vm.step === 2 ? _c('product-selection', {
    on: {
      "select:products": _vm.onSelectProducts
    }
  }) : _vm._e(), _c('r-error-message', {
    attrs: {
      "errors": [_vm.fetchCreateDashboardError, _vm.fetchProductsError]
    }
  }), _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', [_c('div', {
    staticClass: "d-flex align-center justify-start mt-3"
  }, [_c('span', {
    staticClass: "creatempd--back-link",
    on: {
      "click": _vm.onBack
    }
  }, [_vm._v(" Back ")])])]), _c('v-col', [_c('p', {
    staticClass: "mt-3"
  }, [_c('b', [_c('span', {
    class: {
      'creatempd--stage-label': _vm.step === 2
    }
  }, [_vm._v("Set up")]), _vm._v(" > "), _c('span', {
    class: {
      'creatempd--stage-label': _vm.step === 1
    }
  }, [_vm._v("Selection")])])])]), _c('v-col', [_c('div', {
    staticClass: "d-flex align-center justify-end"
  }, [_c('r-button', {
    staticClass: "mr-2",
    attrs: {
      "outlined": true,
      "label": _vm.$t('global.actions.cancel')
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('cancel');
      }
    }
  }), _vm.step === 1 ? _c('r-button', {
    staticClass: "mr-2",
    attrs: {
      "label": _vm.$t('global.actions.continue'),
      "disabled": _vm.isNameUndefined
    },
    on: {
      "click": _vm.onContinue
    }
  }) : _vm._e(), _vm.step === 2 ? _c('r-button', {
    staticClass: "mr-2",
    attrs: {
      "disabled": _vm.isProductsPayloadUndefined,
      "label": _vm.$t('dashboards.createDashboard.createDashboardBtn'),
      "loading": _vm.fetchingCreateDashboard
    },
    on: {
      "click": _vm.onCreateDashboard
    }
  }) : _vm._e()], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }