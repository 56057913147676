<template>
  <v-row class="ma-0 brandsdropdown d-flex justify-center">
    <r-select
      key="brandsdropdown--rselect"
      class="brandsdropdown--select"
      :items="items"
      :selected-items-for-chip="selectedBrands"
      :show-search="true"
      :search-placeholder="$t('brandsDropdown.searchPlaceHolderText')"
      :allow-empty="false"
      :multiple="true"
      :disabled="disabled"
      :always-open="alwaysOpen"
      :infinite="true"
      @change:selected="onSelectBrand"
      @change:deselected="onDeselectBrand"
      @click:prepend-item="onClickCreateNewBrand"
      @on:get-more="onGetMoreItems"
      @change:search="onSearchChange"
    >
      <template
        v-if="showAutogeneratedBrandsFilter"
        v-slot:filters
      >
        <div class="brandsdropdown--filters pl-2">
          <r-switch
            :value="showAutogeneratedBrands"
            :disabled="disabled"
            @change="onShowAutogenerateBrandsFilterChange"
          />
          <label class="px-2">
            {{ $t('brandsDropdown.filters.showAutomaticallyCreated') }}
          </label>
        </div>
      </template>
      <template v-slot:item="slotProps">
        <div>
          <r-badge
            :name="slotProps.item.label"
            :color="slotProps.item.color"
            prepend-icon="mdi-alpha-b-circle"
          />
        </div>
      </template>
      <template v-slot:prepend-item>
        <v-col class="d-flex pa-0 rselectitem">
          <button
            class="rbrandsdropdown--create-brand-button rselectitem--row"
            @click="onClickCreateNewBrand"
          >
            <v-icon
              dense
              class="pl-2"
            >
              add
            </v-icon>
            <span> {{ $t('brandsDropdown.create') }}</span>
          </button>
        </v-col>
      </template>
    </r-select>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import RSelect from '@/components/library/molecules/RSelect'
import RBadge from '@/components/library/atoms/RBadge'
import RSwitch from '@/components/library/atoms/RSwitch'
import { generateParams } from '@/utils/store'
import * as brandsApi from '../../../api/brands'

export default {
  name: 'BrandsDropdown',
  components: {
    RSwitch,
    RBadge,
    RSelect,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    alwaysOpen: {
      type: Boolean,
      default: false,
    },
    preselectedBrands: {
      type: Array,
      default: () => [],
    },
    showAutogeneratedBrandsFilter: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showAutogeneratedBrands: true,
    searchValue: '',
    selectedBrands: [],
  }),
  computed: {
    ...mapGetters('projects', [
      'selectedProjectId',
    ]),
    ...mapState('brands', [
      'brandsSummary',
      'customBrands',
    ]),
    items() {
      if (this.$data.showAutogeneratedBrands) {
        return this.mapBrandsToDropdownItems({
          brands: this.brandsSummary,
        })
      }
      return this.mapBrandsToDropdownItems({
        brands: this.customBrands,
      })
    },
    selectedBrandIds() {
      return this.$data.selectedBrands.map(({ value }) => value)
    },
  },
  async mounted() {
    const { preselectedBrands } = this.$props
    if (preselectedBrands.length) {
      this.$data.selectedBrands = (await brandsApi.getAll({
        brandIds: preselectedBrands,
        pageSize: preselectedBrands.length,
      })).brands.map(({ _id, name }) => ({
        label: name,
        value: _id,
      }))
    }
    this.fetchBrandsData({ projectId: this.selectedProjectId })
    this.$watch('selectedBrandIds', this.watchSelectedBrandIdsChange)
  },
  methods: {
    ...mapActions('brands', [
      'fetchBrandsSummary',
      'fetchCustomBrands',
    ]),
    fetchBrandsData(params) {
      if (this.$data.showAutogeneratedBrands) {
        this.fetchBrandsSummary(params)
      } else {
        this.fetchCustomBrands({ ...params, autoGenerated: false })
      }
    },
    onClickCreateNewBrand() {
      this.$emit('open:create-brand-modal')
    },
    onSelectBrand({ value: brand }) {
      this.$data.selectedBrands = [...this.$data.selectedBrands, brand]
    },
    onDeselectBrand({ value: brand }) {
      this.$data.selectedBrands = this.$data.selectedBrands
        .filter(({ value }) => value !== brand.value)
    },
    onShowAutogenerateBrandsFilterChange({ value: showAutogeneratedBrands }) {
      this.$data.showAutogeneratedBrands = showAutogeneratedBrands
      this.fetchBrandsData({ projectId: this.selectedProjectId })
    },
    mapBrandsToDropdownItems({ brands }) {
      return brands.map(({ _id, name, color }) => ({
        label: name,
        value: _id,
        selected: this.selectedBrandIds.includes(_id),
        color,
      }))
    },
    onGetMoreItems() {
      const pageParams = generateParams('brands')
      if (!pageParams) {
        return
      }
      let params = {
        projectId: this.selectedProjectId,
        isInfinityScroll: true,
        search: this.searchValue,
      }
      params = { ...params, ...pageParams }
      this.fetchBrandsData(params)
    },
    onSearchChange({ value }) {
      this.searchValue = value
      const params = { projectId: this.selectedProjectId, search: value, page: 1 }
      this.fetchBrandsData(params)
    },
    watchSelectedBrandIdsChange() {
      this.$emit('change', { value: this.selectedBrandIds, items: this.items })
    },
  },
}
</script>

<style scoped>
.brandsdropdown--select {
  width: 100%;
}
.brandsdropdown--filters {
  color: var(--primary-color);
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  border-bottom: 1px solid var(--r-border-color);
  align-items: center;
  padding: 8px 0;
  display: flex;
}
.rbrandsdropdown--create-brand-button {
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid var(--r-border-color);
}
.rbrandsdropdown--create-brand-button span {
  margin-left: 19px;
  font-size: 12px;
  color: rgba(63, 63, 63, 0.75);
}
.rselectitem .rselectitem--row:hover {
  background: var(--r-light-grey);
  cursor: pointer;
}
</style>
