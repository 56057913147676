var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "ma-0 brandsdropdown d-flex justify-center"
  }, [_c('r-select', {
    key: "brandsdropdown--rselect",
    staticClass: "brandsdropdown--select",
    attrs: {
      "items": _vm.items,
      "selected-items-for-chip": _vm.selectedBrands,
      "show-search": true,
      "search-placeholder": _vm.$t('brandsDropdown.searchPlaceHolderText'),
      "allow-empty": false,
      "multiple": true,
      "disabled": _vm.disabled,
      "always-open": _vm.alwaysOpen,
      "infinite": true
    },
    on: {
      "change:selected": _vm.onSelectBrand,
      "change:deselected": _vm.onDeselectBrand,
      "click:prepend-item": _vm.onClickCreateNewBrand,
      "on:get-more": _vm.onGetMoreItems,
      "change:search": _vm.onSearchChange
    },
    scopedSlots: _vm._u([_vm.showAutogeneratedBrandsFilter ? {
      key: "filters",
      fn: function fn() {
        return [_c('div', {
          staticClass: "brandsdropdown--filters pl-2"
        }, [_c('r-switch', {
          attrs: {
            "value": _vm.showAutogeneratedBrands,
            "disabled": _vm.disabled
          },
          on: {
            "change": _vm.onShowAutogenerateBrandsFilterChange
          }
        }), _c('label', {
          staticClass: "px-2"
        }, [_vm._v(" " + _vm._s(_vm.$t('brandsDropdown.filters.showAutomaticallyCreated')) + " ")])], 1)];
      },
      proxy: true
    } : null, {
      key: "item",
      fn: function fn(slotProps) {
        return [_c('div', [_c('r-badge', {
          attrs: {
            "name": slotProps.item.label,
            "color": slotProps.item.color,
            "prepend-icon": "mdi-alpha-b-circle"
          }
        })], 1)];
      }
    }, {
      key: "prepend-item",
      fn: function fn() {
        return [_c('v-col', {
          staticClass: "d-flex pa-0 rselectitem"
        }, [_c('button', {
          staticClass: "rbrandsdropdown--create-brand-button rselectitem--row",
          on: {
            "click": _vm.onClickCreateNewBrand
          }
        }, [_c('v-icon', {
          staticClass: "pl-2",
          attrs: {
            "dense": ""
          }
        }, [_vm._v(" add ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('brandsDropdown.create')))])], 1)])];
      },
      proxy: true
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }