var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('r-box', {
    staticClass: "createdashboard",
    attrs: {
      "title": _vm.$t('dashboards.createDashboard.newDashboard')
    }
  }, [_vm.type === _vm.TYPES.SINGLE_PRODUCT ? _c('create-single-product-dashboard', {
    on: {
      "back": _vm.onClickBack,
      "cancel": _vm.onClickCancel,
      "create": _vm.onClickCreate
    }
  }) : _vm._e(), _vm.type === _vm.TYPES.SINGLE_CATEGORY ? _c('create-single-category-dashboard', {
    on: {
      "back": _vm.onClickBack,
      "cancel": _vm.onClickCancel,
      "create": _vm.onClickCreate
    }
  }) : _vm._e(), _vm.type === _vm.TYPES.SINGLE_TAG ? _c('create-single-tag-dashboard', {
    on: {
      "back": _vm.onClickBack,
      "cancel": _vm.onClickCancel,
      "create": _vm.onClickCreate
    }
  }) : _vm._e(), _vm.type === _vm.TYPES.SINGLE_BRAND ? _c('create-single-brand-dashboard', {
    on: {
      "back": _vm.onClickBack,
      "cancel": _vm.onClickCancel,
      "create": _vm.onClickCreate
    }
  }) : _vm._e(), _vm.type === _vm.TYPES.MULTI_PRODUCT ? _c('create-multi-product-dashboard', {
    on: {
      "back": _vm.onClickBack,
      "cancel": _vm.onClickCancel,
      "create": _vm.onClickCreate
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }