<template>
  <r-box
    class="createdashboard"
    :title="$t('dashboards.createDashboard.newDashboard')"
  >
    <create-single-product-dashboard
      v-if="type === TYPES.SINGLE_PRODUCT"
      @back="onClickBack"
      @cancel="onClickCancel"
      @create="onClickCreate"
    />
    <create-single-category-dashboard
      v-if="type === TYPES.SINGLE_CATEGORY"
      @back="onClickBack"
      @cancel="onClickCancel"
      @create="onClickCreate"
    />
    <create-single-tag-dashboard
      v-if="type === TYPES.SINGLE_TAG"
      @back="onClickBack"
      @cancel="onClickCancel"
      @create="onClickCreate"
    />
    <create-single-brand-dashboard
      v-if="type === TYPES.SINGLE_BRAND"
      @back="onClickBack"
      @cancel="onClickCancel"
      @create="onClickCreate"
    />
    <create-multi-product-dashboard
      v-if="type === TYPES.MULTI_PRODUCT"
      @back="onClickBack"
      @cancel="onClickCancel"
      @create="onClickCreate"
    />
  </r-box>
</template>

<script>
import RBox from '@/components/library/molecules/RBox'
import { TYPES_OF_DASHBOARD } from '@/utils/constants'
import { mapActions, mapGetters, mapState } from 'vuex'
import CreateSingleProductDashboard from './CreateSingleProductDashboard'
import CreateSingleCategoryDashboard from './CreateSingleCategoryDashboard'
import CreateSingleTagDashboard from './CreateSingleTagDashboard'
import CreateSingleBrandDashboard from './CreateSingleBrandDashboard'
import CreateMultiProductDashboard from './CreateMultiProductDashboard.vue'

export default {
  name: 'CreateDashboard',
  components: {
    RBox,
    CreateSingleProductDashboard,
    CreateSingleCategoryDashboard,
    CreateSingleTagDashboard,
    CreateSingleBrandDashboard,
    CreateMultiProductDashboard,
  },
  data: () => ({
    type: '',
  }),
  computed: {
    ...mapGetters('projects', [
      'selectedProjectId',
    ]),
    ...mapState('dashboards', [
      'newlyCreatedDashboardId',
      'fetchingCreateDashboard',
      'fetchCreateDashboardError',
    ]),
  },
  beforeMount() {
    const { type } = this.$route.params
    this.type = type
  },
  beforeDestroy() {
    this.resetErrors()
  },
  created() {
    this.TYPES = TYPES_OF_DASHBOARD
  },
  methods: {
    ...mapActions('dashboards', [
      'createDashboard',
      'clearErrors',
    ]),
    resetErrors() {
      this.clearErrors({ fetchCreateDashboardError: '' })
    },
    onClickBack() {
      this.resetErrors()
      this.$router.push('/dashboards/add')
    },
    onClickCancel() {
      this.resetErrors()
      this.$router.push('/dashboards')
    },
    async onClickCreate({ ...data }) {
      this.resetErrors()

      const projectId = this.selectedProjectId
      const payload = {
        projectId,
        type: this.type,
        ...data,
      }

      await this.createDashboard(payload)

      if (!this.fetchingCreateDashboard
        && !this.fetchCreateDashboardError
        && this.newlyCreatedDashboardId) {
        await this.$router.push(`/dashboards/${this.newlyCreatedDashboardId}`)
      }
    },
  },
}
</script>

<style scoped>
.createdashboard {
  width: 876px;
  margin: 0 auto;
}
</style>
