<template>
  <v-form
    ref="form"
    v-model="valid"
    class="datafilters"
    @submit.prevent
  >
    <div class="datafilters--options">
      <v-row>
        <v-col class="datafilters--option datafilters--totalreviews">
          <r-num-reviews-filter
            :min-reviews="filters.minReviews"
            :max-reviews="filters.maxReviews"
            @change:min-reviews="onChangeMinReviews"
            @change:max-reviews="onChangeMaxReviews"
          />
        </v-col>
        <v-col class="datafilters--option datafilters--products">
          <p class="datafilters--filterlabel">
            {{ $t('resources.products') }}
          </p>
          <products-dropdown
            class="datafilters--filter"
            :preselected-products="preselectedProducts"
            @change="onProductsSelectionChange"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="datafilters--option datafilters--lastrevieweddate">
          <p class="datafilters--filterlabel">
            {{ $t('dataFilters.lastReviewed') }}
          </p>
          <div class="datafilters--filter datafilters--range-container">
            <span>Start</span>
            <r-text-field
              :value="filters.minLastReviewedDate"
              :error-message="errors.minLastReviewedDate.message"
              placeholder="2022-09-09"
              @change="(e) => onDateChange(e, 'minLastReviewedDate')"
            />

            <span>End</span>
            <r-text-field
              :value="filters.maxLastReviewedDate"
              :error-message="errors.maxLastReviewedDate.message"
              placeholder="2022-09-10"
              @change="(e) => onDateChange(e, 'maxLastReviewedDate')"
            />
          </div>
          <div class="datafilters--range-error">
            {{ errors.minLastReviewedDate.message || errors.maxLastReviewedDate.message }}
          </div>
        </v-col>
        <v-col
          class="datafilters--option datafilters--creationdate"
        >
          <p class="datafilters--filterlabel">
            {{ $t('dataFilters.creationDate') }}
          </p>
          <div class="datafilters--filter datafilters--range-container">
            <span>Start</span>
            <r-text-field
              :value="filters.minCreationDate"
              :error-message="errors.minCreationDate.message"
              placeholder="2022-09-09"
              @change="(e) => onDateChange(e, 'minCreationDate')"
            />

            <span>End</span>
            <r-text-field
              :value="filters.maxCreationDate"
              :error-message="errors.maxCreationDate.message"
              placeholder="2022-09-10"
              @change="(e) => onDateChange(e, 'maxCreationDate')"
            />
          </div>
          <div class="datafilters--range-error">
            {{ errors.maxCreationDate.message || errors.minCreationDate.message }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="datafilters--option datafilters--starrating">
          <p class="datafilters--filterlabel">
            {{ $t('dataFilters.starRating') }}
          </p>
          <div class="datafilters--filter datafilters--range-container">
            <span>Min</span>
            <r-rating
              :value="filters.minRating || 1"
              :readonly="false"
              :is-half-incrementing="false"
              @change="onMinRatingChange"
            />

            <span>Max</span>
            <r-rating
              :value="filters.maxRating || 5"
              :readonly="false"
              :is-half-incrementing="false"
              @change="onMaxRatingChange"
            />
          </div>
        </v-col>
        <v-col class="datafilters--option datafilters--hostnames">
          <p class="datafilters--filterlabel">
            {{ $t('dataFilters.domains') }}
          </p>
          <hostnames-dropdown
            class="datafilters--filter"
            :preselected-hostnames="preselectedHostnames"
            @change="onHostnamesSelectionChange"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="datafilters--option datafilters--tags">
          <p class="datafilters--filterlabel">
            {{ $t('resources.tags') }}
          </p>
          <tags-dropdown
            :preselected-tags="preselectedTags"
            class="datafilters--filter"
            @open:create-tag-modal="$emit('open:create-tag-modal')"
            @change="onTagsSelectionChange"
          />
        </v-col>
        <v-col class="datafilters--option datafilters--brands px-2">
          <p class="datafilters--filterlabel">
            {{ $t('resources.brands') }}
          </p>
          <brands-dropdown
            class="datafilters--filter"
            :show-autogenerated-brands-filter="true"
            :preselected-brands="preselectedBrands"
            @open:create-brand-modal="$emit('open:create-brand-modal')"
            @change="onBrandsSelectionChange"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="datafilters--option datafilters--categories">
          <p class="datafilters--filterlabel">
            {{ $t('resources.categories') }}
          </p>
          <categories-dropdown
            class="datafilters--filter categories-filter"
            :show-autogenerated-categories-filter="true"
            :preselected-categories="preselectedCategories"
            @open:create-category-modal="$emit('open:create-category-modal')"
            @change="onCategoriesSelectionChange"
          />
        </v-col>
      </v-row>
    </div>
    <div class="datafilters--actionbtns">
      <r-button
        type="button"
        :label="$t('global.actions.cancel')"
        outlined
        @click="onClickCancel"
      />
      <r-button
        :label="$t('global.actions.apply')"
        type="button"
        :disabled="hasErrors"
        @click="onClickApply"
      />
    </div>
  </v-form>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { DATE_FORMAT_REGEX } from '@/utils/constants'
import isValidDate from '@/utils/isValidDate'
import RButton from '@/components/library/atoms/RButton'
import HostnamesDropdown from '@/components/app/data/HostnamesDropdown.vue'
import ProductsDropdown from '@/components/app/data/ProductsDropdown'
import RNumReviewsFilter from '@/components/library/organisms/RNumReviewsFilter'
import RTextField from '@/components/library/molecules/RTextField.vue'
import RRating from '@/components/library/atoms/RRating.vue'
import CategoriesDropdown from '@/components/app/data/CategoriesDropdown.vue'
import BrandsDropdown from '@/components/app/data/BrandsDropdown.vue'
import TagsDropdown from '@/components/app/data/TagsDropdown.vue'

export default {
  name: 'DataFilters',
  components: {
    RNumReviewsFilter,
    RButton,
    HostnamesDropdown,
    RTextField,
    RRating,
    CategoriesDropdown,
    BrandsDropdown,
    TagsDropdown,
    ProductsDropdown,
  },
  data: () => ({
    filters: {},
    allowedMinValue: 0,
    valid: false,
    errors: {
      minCreationDate: {
        hasErrors: false,
        message: '',
      },
      maxCreationDate: {
        hasErrors: false,
        message: '',
      },
      minLastReviewedDate: {
        hasErrors: false,
        message: '',
      },
      maxLastReviewedDate: {
        hasErrors: false,
        message: '',
      },
    },
  }),
  computed: {
    ...mapState('products', [
      'products',
    ]),
    ...mapState('urls', [
      'hostnames',
    ]),
    ...mapGetters('filters', [
      'appliedFilters',
    ]),
    preselectedHostnames() {
      return this.appliedFilters.hostnames?.map((name) => ({ name })) ?? []
    },
    preselectedProducts() {
      return this.appliedFilters.productIds?.map((_id) => _id) ?? []
    },
    preselectedBrands() {
      return this.appliedFilters.brandIds?.map((_id) => _id) ?? []
    },
    preselectedCategories() {
      return this.appliedFilters.categoryIds?.map((_id) => _id) ?? []
    },
    preselectedTags() {
      return this.appliedFilters.tagIds?.map((_id) => _id) ?? []
    },
    hasErrors() {
      const { errors, valid: isFormValid } = this
      const hasCustomError = Object.entries(errors).some(([, errorData]) => errorData.hasErrors)

      return hasCustomError || !isFormValid
    },
  },
  beforeMount() {
    this.$data.filters = this.appliedFilters
  },
  methods: {
    ...mapActions('products', [
      'fetchProducts',
    ]),
    ...mapActions('filters', [
      'setFilters',
      'resetFilters',
    ]),
    onProductsSelectionChange({ value: selectedProductIds }) {
      this.$data.filters = ({ ...this.$data.filters, productIds: selectedProductIds })
    },
    onHostnamesSelectionChange({ value: selectedHostnames }) {
      this.$data.filters = ({ ...this.$data.filters, hostnames: selectedHostnames })
    },
    onTagsSelectionChange({ value: selectedTagIds }) {
      this.$data.filters = ({ ...this.$data.filters, tagIds: selectedTagIds })
    },
    onBrandsSelectionChange({ value: selectedBrandIds }) {
      this.$data.filters = ({ ...this.$data.filters, brandIds: selectedBrandIds })
    },
    onCategoriesSelectionChange({ value: selectedCategoryIds }) {
      this.$data.filters = ({ ...this.$data.filters, categoryIds: selectedCategoryIds })
    },
    onChangeMinReviews({ value }) {
      this.$data.filters = ({ ...this.$data.filters, minReviews: value })
    },
    onChangeMaxReviews({ value }) {
      this.$data.filters = ({ ...this.$data.filters, maxReviews: value })
    },
    onDateChange({ value }, field) {
      const isDateValid = isValidDate(value, DATE_FORMAT_REGEX) || !value
      if (isDateValid) {
        this.$data.filters = ({ ...this.$data.filters, [field]: value })
      }
      const message = isDateValid ? '' : this.$i18n.t('global.validations.messages.invalidDateFormat', { format: 'YYYY-MM-DD' })
      this.$set(this.errors[field], 'message', message)
      this.$set(this.errors[field], 'hasErrors', !isDateValid)
    },
    onMinRatingChange({ value }) {
      if (this.$data.filters.maxRating && value > this.$data.filters.maxRating) {
        this.$data.filters = ({
          ...this.$data.filters,
          minRating: value,
          maxRating: value,
        })
      } else {
        this.$data.filters = ({ ...this.$data.filters, minRating: value })
      }
    },
    onMaxRatingChange({ value }) {
      if (this.$data.filters.minRating && value < this.$data.filters.minRating) {
        this.$data.filters = ({
          ...this.$data.filters,
          minRating: value,
          maxRating: value,
        })
      } else {
        this.$data.filters = ({ ...this.$data.filters, maxRating: value })
      }
    },
    onClickApply() {
      if (!this.$refs.form.validate() || this.isDatesIntervalInValid()) {
        return
      }

      this.setFilters({ ...this.$data.filters })
      this.$emit('close')
    },
    onClickCancel() {
      this.$emit('close')
    },
    isDatesIntervalInValid() {
      let hasErrors = false
      const datesFilters = [['minCreationDate', 'maxCreationDate'], ['minLastReviewedDate', 'maxLastReviewedDate']]
      datesFilters.forEach(([filterMin, filterMax]) => {
        if (this.$data.filters[filterMax] && this.$data.filters[filterMin]) {
          const isAfter = this.$data.filters[filterMin] > this.$data.filters[filterMax]
          const message = isAfter ? `${filterMin} Date should be less than ${filterMax}` : ''
          this.$set(this.errors[filterMin], 'message', message)
          this.$set(this.errors[filterMin], 'hasErrors', isAfter)
          hasErrors = isAfter
        }
      })
      return hasErrors
    },
  },
}
</script>

<style scoped>
  .datafilters {
    padding: 16px;
  }

  .datafilters--options > .row {
    width: 900px;
  }

  .datafilters--filterlabel {
    font-weight: 500;
  }

  .datafilters--filter {
    display: flex;
    flex-grow: 1;
    align-items: center;
    align-content: center;
    gap: 10px;
  }

  .datafilters--filter > span {
    margin: 0;
    color: var(--r-text-color);
  }

  .categories-filter /deep/ .selector-option-container {
    max-height: 22rem !important;
  }

  .categories-filter /deep/ .selector-popup--items {
    max-height: 9rem !important;
  }

  /deep/ .datafilters--option > .numreviewsfilter {
    width: 25rem;
  }

  /deep/ .numreviewsfilter--field .rtextfield {
    width: 12rem;
  }

  .datafilters--range-container {
    display: flex;
    align-items: center;
  }

  .datafilters--range-container > .rtextfield {
    width: 90px;
  }

  /deep/ .datafilters--range-container .rtextfield > .v-input {
    margin: 0 !important;
  }

  /deep/ .datafilters--range-container .rtextfield > .v-input .v-text-field__details {
    display: none;
  }

  .datafilters--actionbtns {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    gap: 8px
  }

  /deep/ .brandsdropdown--select,
  /deep/ .tagsdropdown--select,
  /deep/ .categoriesdropdown--select,
  /deep/ .hostnamesdropdown--select,
  /deep/ .productsDropdown--select {
    width: 100%;
  }
  .datafilters--range-error {
    font-size: 12px;
    color: #fd5155;
    margin-top: 5px;
  }
  .rbutton {
    padding: 8px 50px !important;
  }
</style>
