<template>
  <v-row class="ma-0 categoriesdropdown d-flex justify-center">
    <r-select
      key="categoriesdropdown--rselect"
      class="categoriesdropdown--select"
      :items="items"
      :selected-items-for-chip="selectedCategories"
      :show-search="true"
      :search-placeholder="$t('categoriesDropdown.searchPlaceHolderText')"
      :allow-empty="false"
      :multiple="true"
      :infinite="true"
      :disabled="disabled"
      :always-open="alwaysOpen"
      @change:selected="onSelectCategory"
      @change:deselected="onDeselectCategory"
      @click:prepend-item="onClickCreateNewCategory"
      @on:get-more="onGetMoreItems"
      @change:search="onSearchChange"
    >
      <template
        v-if="showAutogeneratedCategoriesFilter"
        v-slot:filters
      >
        <div class="categoriesdropdown--filters pl-2">
          <r-switch
            :value="showAutogeneratedCategories"
            :disabled="disabled"
            @change="onShowAutogenerateCategoriesFilterChange"
          />
          <label class="px-2">
            {{ $t('categoriesDropdown.filters.showAutomaticallyCreated') }}
          </label>
        </div>
      </template>
      <template v-slot:item="slotProps">
        <div>
          <r-badge
            :name="slotProps.item.label"
            :color="slotProps.item.color"
            prepend-icon="mdi-view-grid"
          />
        </div>
      </template>
      <template v-slot:prepend-item>
        <div class="d-flex rselectitem">
          <button
            class="rcategoriesdropdown--create-category-button rselectitem--row"
            @click="onClickCreateNewCategory"
          >
            <v-icon
              class="pl-2"
              dense
            >
              add
            </v-icon>
            <span>{{ $t('categoriesDropdown.create') }}</span>
          </button>
        </div>
      </template>
    </r-select>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import RSelect from '@/components/library/molecules/RSelect'
import RBadge from '@/components/library/atoms/RBadge'
import RSwitch from '@/components/library/atoms/RSwitch'
import { generateParams } from '@/utils/store'
import * as categoriesApi from '../../../api/categories'

export default {
  name: 'CategoriesDropdown',
  components: {
    RBadge,
    RSelect,
    RSwitch,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    alwaysOpen: {
      type: Boolean,
      default: false,
    },
    preselectedCategories: {
      type: Array,
      default: () => [],
    },
    showAutogeneratedCategoriesFilter: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showAutogeneratedCategories: true,
    searchValue: '',
    selectedCategories: [],
  }),
  computed: {
    ...mapGetters('projects', [
      'selectedProjectId',
    ]),
    ...mapState('categories', [
      'categoriesSummary',
      'customCategories',
    ]),
    items() {
      if (this.$data.showAutogeneratedCategories) {
        return this.mapCategoriesToDropdownItems({
          categories: this.categoriesSummary,
        })
      }
      return this.mapCategoriesToDropdownItems({
        categories: this.customCategories,
      })
    },
    selectedCategoryIds() {
      return this.$data.selectedCategories.map(({ value }) => value)
    },
  },
  async mounted() {
    const { preselectedCategories } = this.$props
    if (preselectedCategories.length) {
      this.$data.selectedCategories = (await categoriesApi.getAll({
        categoryIds: preselectedCategories,
        pageSize: preselectedCategories.length,
      })).categories.map(({ _id, name }) => ({
        label: name,
        value: _id,
      }))
    }
    this.fetchCategoriesData({ projectId: this.selectedProjectId })
    this.$watch('selectedCategoryIds', this.watchSelectedCategoryIdsChange)
  },
  methods: {
    ...mapActions('categories', [
      'fetchCategoriesSummary',
      'fetchCustomCategories',
    ]),
    fetchCategoriesData(params) {
      if (this.$data.showAutogeneratedCategories) {
        this.fetchCategoriesSummary(params)
      } else {
        this.fetchCustomCategories({ ...params, autoGenerated: false })
      }
    },
    onClickCreateNewCategory() {
      this.$emit('open:create-category-modal')
    },
    onSelectCategory({ value: category }) {
      this.$data.selectedCategories = [...this.$data.selectedCategories, category]
    },
    onDeselectCategory({ value: category }) {
      this.$data.selectedCategories = this.$data.selectedCategories
        .filter(({ value }) => value !== category.value)
    },
    onShowAutogenerateCategoriesFilterChange({ value: showAutogeneratedCategories }) {
      this.$data.showAutogeneratedCategories = showAutogeneratedCategories
      this.fetchCategoriesData({ projectId: this.selectedProjectId })
    },
    mapCategoriesToDropdownItems({ categories }) {
      return categories.map(({ _id, name, color }) => ({
        label: name,
        value: _id,
        selected: this.selectedCategoryIds.includes(_id),
        color,
      }))
    },
    onGetMoreItems() {
      const pageParams = generateParams('categories')
      if (!pageParams) {
        return
      }
      let params = {
        projectId: this.selectedProjectId,
        isInfinityScroll: true,
        search: this.searchValue,
      }
      params = { ...params, ...pageParams }
      this.fetchCategoriesData(params)
    },
    onSearchChange({ value }) {
      this.searchValue = value
      const params = { projectId: this.selectedProjectId, search: value, page: 1 }
      this.fetchCategoriesData(params)
    },
    watchSelectedCategoryIdsChange() {
      this.$emit('change', { value: this.selectedCategoryIds, items: this.items })
    },
  },
}
</script>

<style scoped>
.categoriesdropdown--select {
  width: 100%;
}
.categoriesdropdown--filters {
  color: var(--primary-color);
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  border-bottom: 1px solid var(--r-border-color);
  align-items: center;
  padding: 8px 0;
  display: flex;
}
.rcategoriesdropdown--create-category-button {
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid var(--r-border-color);
}
.rcategoriesdropdown--create-category-button span {
  margin-left: 19px;
  font-size: 12px;
  color: rgba(63, 63, 63, 0.75);
}
.rselectitem .rselectitem--row:hover {
  background: var(--r-light-grey);
  cursor: pointer;
}
</style>
