var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "ma-0 tagsdropdown"
  }, [_c('r-select', {
    key: "tagsdropdown--rselect",
    staticClass: "tagsdropdown--select",
    attrs: {
      "items": _vm.items,
      "selected-items-for-chip": _vm.selectedTags,
      "show-search": true,
      "search-placeholder": _vm.$t('tagsDropdown.searchPlaceHolderText'),
      "allow-empty": false,
      "multiple": true,
      "disabled": _vm.disabled,
      "always-open": _vm.alwaysOpen,
      "infinite": true
    },
    on: {
      "change:selected": _vm.onSelectTag,
      "change:deselected": _vm.onDeselectTag,
      "click:prepend-item": _vm.onClickCreateNewTag,
      "on:get-more": _vm.onGetMoreItems,
      "change:search": _vm.onSearchChange
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(slotProps) {
        return [_c('div', [_c('r-badge', {
          attrs: {
            "name": slotProps.item.label,
            "color": slotProps.item.color
          }
        })], 1)];
      }
    }, {
      key: "prepend-item",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex rselectitem"
        }, [_c('button', {
          staticClass: "rtagsdropdown--create-tag-button rselectitem--row",
          on: {
            "click": _vm.onClickCreateNewTag
          }
        }, [_c('v-icon', {
          staticClass: "pl-2",
          attrs: {
            "dense": ""
          }
        }, [_vm._v(" add ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('tagsDropdown.create')))])], 1)])];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }