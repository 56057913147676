<template>
  <v-row class="ma-0 productsdropdown d-flex justify-center">
    <r-select
      :infinite="true"
      multiple
      class="projectsdropdown--select"
      :items="items"
      :selected-items-for-chip="selectedProducts"
      @change:selected="onSelectProduct"
      @change:deselected="onDeselectProduct"
      @on:get-more="onGetMoreItems"
      @change:search="onSearchChange"
    >
      <template
        v-if="selectLabel"
        v-slot:label
      >
        <span>{{ selectLabel }}</span>
      </template>
    </r-select>
  </v-row>
</template>

<script>
import RSelect from '@/components/library/molecules/RSelect'
import { mapActions, mapGetters, mapState } from 'vuex'
import { generateParams } from '@/utils/store'
import * as productsApi from '../../../api/products'

export default {
  name: 'ProductsDropdown',
  components: {
    RSelect,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    preselectedProducts: {
      type: Array,
      default: () => [],
    },
    selectLabel: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    search: '',
    selectedProducts: [],
  }),
  computed: {
    ...mapState('products', [
      'fetchingProductsSummary',
      'productsSummary',
      'fetchProductsError',
    ]),
    ...mapGetters('projects', [
      'selectedProjectId',
    ]),
    items() {
      return this.mapProductsToDropdownItems({
        products: this.productsSummary,
      })
    },
    selectedProductIds() {
      return this.$data.selectedProducts.map(({ value }) => value)
    },
  },
  async beforeMount() {
    const { preselectedProducts } = this.$props
    if (preselectedProducts.length) {
      this.$data.selectedProducts = (await productsApi.getAll({
        productIds: preselectedProducts,
        pageSize: preselectedProducts.length,
      })).products.map(({ _id, name }) => ({
        label: name,
        value: _id,
      }))
    }
    if (!this.productsSummary.length) {
      this.fetchProductsSummary()
    }
    this.$watch('selectedProductIds', this.watchSelectedProductIdsChange)
  },
  methods: {
    ...mapActions('products', [
      'fetchProductsSummary',
    ]),
    onSelectProduct({ value: product }) {
      this.$data.selectedProducts = [...this.$data.selectedProducts, product]
    },
    onDeselectProduct({ value: product }) {
      this.$data.selectedProducts = this.$data.selectedProducts
        .filter(({ value }) => value !== product.value)
    },
    mapProductsToDropdownItems({ products }) {
      return products.map(({ _id, name }) => ({
        label: name,
        value: _id,
        selected: this.selectedProductIds.includes(_id),
      }))
    },
    onGetMoreItems() {
      const pageParams = generateParams('products')
      if (!pageParams) {
        return
      }
      let params = {
        projectId: this.selectedProjectId,
        isInfinityScroll: true,
        search: this.search,
      }
      params = { ...params, ...pageParams }
      this.fetchProductsSummary(params)
    },
    onSearchChange({ value }) {
      this.search = value
      this.fetchProductsSummary({ projectId: this.selectedProjectId, search: value, page: 1 })
    },
    watchSelectedProductIdsChange() {
      this.$emit('change', { value: this.selectedProductIds, items: this.items })
    },
  },
}
</script>

<style scoped>
.projectsdropdown--select {
  display: flex;
  flex-grow: 1;
  align-items: center;
  align-content: center;
  gap: 10px;
}
</style>
