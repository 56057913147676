var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "ma-0 hostnamesdropdown d-flex justify-center"
  }, [_c('r-select', {
    staticClass: "hostnamesdropdown--select",
    attrs: {
      "multiple": "",
      "items": _vm.filteredHostnames,
      "selected-items-for-chip": _vm.selectedHostnameItems
    },
    on: {
      "change:selected": _vm.onSelectHostname,
      "change:deselected": _vm.onDeselectHostname,
      "change:search": _vm.onSearchChange
    },
    scopedSlots: _vm._u([_vm.selectLabel ? {
      key: "label",
      fn: function fn() {
        return [_c('span', [_vm._v(_vm._s(_vm.selectLabel))])];
      },
      proxy: true
    } : null], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }