var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "numreviewsfilter"
  }, [_c('p', {
    staticClass: "numreviewsfilter--label"
  }, [_vm._v(" " + _vm._s(_vm.$t('numReviewsFilter.totalNumberOfReviews')) + " ")]), _c('div', {
    staticClass: "numreviewsfilter--field d-flex justify-space-between"
  }, [_c('r-text-field', {
    staticClass: "minreviews-filter",
    attrs: {
      "placeholder": "20",
      "type": "number",
      "min": 0,
      "value": _vm.minReviews,
      "rules": _vm.minReviewsRules
    },
    on: {
      "change": _vm.onChangeMinReviews
    }
  }), _c('span', {
    staticClass: "mt-2"
  }, [_vm._v(" - ")]), _c('r-text-field', {
    staticClass: "maxreviews-filter",
    attrs: {
      "placeholder": "30",
      "type": "number",
      "min": _vm.maxReviewsMinValue,
      "value": _vm.maxReviews,
      "rules": _vm.maxReviewsRules
    },
    on: {
      "change": _vm.onChangeMaxReviews
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }